import React , { useState, useEffect, useCallback } from 'react';
import { Link, graphql } from 'gatsby';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';


import { Script } from "gatsby";
import { 
  LayoutLp,
  SEO,
  AssetImage,
  CLpModalNewyear,
} from '../../../components/_index';
import { useMobile } from '../../../utils/use-series';
import '../../../assets/_sass/page/sp/newyear2024.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();
  const [scrollFlag, setScrollFlag] = useState<Boolean>(false);
  useEffect(() => {
    window.addEventListener('scroll', scrollEvent);
    return () => window.removeEventListener('scroll', scrollEvent);
  }, []);
  const scrollEvent = useCallback(() => {
    if (
      window.scrollY > document.querySelectorAll('.l_headerLpNewyear')[0].clientHeight
    ) {
      document.querySelectorAll('.lp_newYearFooter')[0].classList.remove('disnon');
    }
  }, [scrollFlag]);

  // Swiper
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [thumbsSwiper1, setThumbsSwiper1] = useState(null);
  const [thumbsSwiper2, setThumbsSwiper2] = useState(null);
  const [thumbsSwiper3, setThumbsSwiper3] = useState(null);

  // modalSwiper
  const [thumbsSwiper4, setThumbsSwiper4] = useState(null);

  // modal
  // event
  const [modalFlagEvent, setModalFlagEvent] = useState(false);
  // osechi
  const [modalFlag, setModalFlag] = useState(false);
  const [modalFlag02, setModalFlag02] = useState(false);
  const [modalFlag03, setModalFlag03] = useState(false);

  const openModalEvent = (e: any) => {
    e.stopPropagation();
    setModalFlagEvent(!modalFlagEvent);
  };
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  const openModal02 = (e: any) => {
    e.stopPropagation();
    setModalFlag02(!modalFlag02);
  };
  const openModal03 = (e: any) => {
    e.stopPropagation();
    setModalFlag03(!modalFlag03);
  };



  return (
    <LayoutLp isNewyear={true}>
      <Script dangerouslySetInnerHTML={{
        __html: `
(function() {
    const tabList = document.querySelectorAll('.lp_tabItem');
    const tabListSp = document.querySelectorAll('.lp_tabItemSp');
    const tabContent = document.querySelectorAll('.lp_tabContentsItem');
    const tabContentSp = document.querySelectorAll('.lp_tabContentsItemSp');

    function tabSwitch() {
      document.querySelectorAll('.active')[0].classList.remove('active');
      this.classList.add('active');
      document.querySelectorAll('.show')[0].classList.remove('show');
      const aryTabs = Array.prototype.slice.call(tabList);
      const index = aryTabs.indexOf(this);
      tabContent[index].classList.add('show');
    };
    for (let i = 0; i < tabList.length; i++) {
      tabList[i].addEventListener('click', tabSwitch);
    }

    function tabSwitchSp() {
      document.querySelectorAll('.activeSp')[0].classList.remove('activeSp');
      this.classList.add('activeSp');
      document.querySelectorAll('.showSp')[0].classList.remove('showSp');
      const aryTabs = Array.prototype.slice.call(tabListSp);
      const index = aryTabs.indexOf(this);
      tabContentSp[index].classList.add('showSp');
    };
    for (let i = 0; i < tabListSp.length; i++) {
      tabListSp[i].addEventListener('click', tabSwitchSp);
    }
})(document)

        `}} />
      <SEO
        title={frontmatter?.title}
        description={frontmatter?.description}
        ogimage={`${process.env.BASE_URL}/assets/images/sp/afternoon/ogimage.png`}
      />
      <div className="lp_newyear">
        <section className="lp_kv">
          <figure className="imgWrapper u_pc">
            <AssetImage
              src="/assets/images/sp/newyear2024/mv.png"
              alt=""
              loading="lazy"
            />
          </figure>
          <figure className="imgWrapper u_sp">
            <AssetImage
              src="/assets/images/sp/newyear2024/mv_sp.png"
              alt=""
              loading="lazy"
            />
          </figure>
          <div className="lp_kvTitle">
            <img src="/assets/images/sp/newyear2024/mv_title.png" className="u_pc" alt=""/>
            <img src="/assets/images/sp/newyear2024/mv_title_sp.png" className="u_sp" alt=""/>
          </div>
          <div className="scrolldown"><span>Scroll</span></div>
        </section>
        <section className="lp_sect01">
          <div className="item1-1"></div>
          <div className="item1-2"></div>
          <div className="item1-3"></div>
          <div className="lp_sect01Bg">
            <div className="lp_sectTitleWrap">
              <img src="/assets/images/sp/newyear2024/icon/icon_restaurant.png" alt=""/>
              <div className="lp_sectTitle" id="restaurant">
                レストラン メニュー
              </div>
            </div>
            <div className="lp_sectTitleSub">Restaurant Menu</div>
            
            <ul className="lp_tabList u_pc">
              <li className="lp_tabItem active">
                ランチ
              </li>
              <li className="lp_tabItem">
                ディナー
              </li>
            </ul>
            <ul className="lp_tabList u_sp">
              <li className="lp_tabItemSp activeSp">
                ランチ
              </li>
              <li className="lp_tabItemSp">
                ディナー
              </li>
            </ul>

            <div className="lp_tabContents u_pc">
              <div className="lp_tabContentsItem show">
                <Swiper
                  onSwiper={setThumbsSwiper}
                  loop={true}
                  spaceBetween={10}
                  slidesPerView={8}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper swiper1"
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2024/restaurant_small.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2024/restaurant4.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2024/restaurant5.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2024/restaurant7.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2024/restaurant10.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2024/restaurant11.png" />
                  </SwiperSlide>
                </Swiper>
                <div className="border"></div>
                <Swiper
                  loop={true}
                  spaceBetween={0}
                  navigation={false}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  <SwiperSlide>
                    <div className="lp_swiperContents">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_tag">ランチ</div>
                          <div className="lp_swiperTextContentsTitle">
                            20F 鉄板焼すみだ<br />
                            <div className="lp_swiperTextContentsTitle--bold">
                              年末・新春鉄板焼ステーキ食べ放題
                            </div>
                          </div>
                          <ul>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2023年12月30日（土）～2024年1月3日（水）<br />
                                1部 11:30～13:00／2部 13:30～15:00
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　10,752円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※お子様、シニア料金あり
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/lj4onaulnb/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant4.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_tag">ランチ</div>
                          <div className="lp_swiperTextContentsTitle">
                            5F 日本料理 源氏香<br />
                            <div className="lp_swiperTextContentsTitle--bold">
                              初釜
                            </div>
                          </div>
                          <ul>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2024年1月1日（元日）、1月2日（火）<br />
                                11:00～16:00
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　3,300円<br />
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※料金には消費税が含まれます。
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/66oc8ia5s8f/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant5.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_tag">ランチ</div>
                          <div className="lp_swiperTextContentsTitle">
                            5F 日本料理 源氏香<br />
                            <div className="lp_swiperTextContentsTitle--bold">
                              新春ランチ会席
                            </div>
                          </div>
                          <ul>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2024年1月1日（元日）～1月3日（水）<br />
                                1部 11:30～13:30／2部 14:00～16:00
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　12,650円
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/y4ngtyltj/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant7.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_tag">ランチ</div>
                          <div className="lp_swiperTextContentsTitle">
                            B1F 中国料理 桂花苑<br />
                            <div className="lp_swiperTextContentsTitle--bold">
                              年末・新春ランチコース
                            </div>
                          </div>
                          <ul>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2023年12月31日（日）～2024年1月3日（水）<br />
                                1部 11:30～13:30／2部 14:00～16:00
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　8,855円〜
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/z8tehjdqh/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant10.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_tag">ランチ</div>
                          <div className="lp_swiperTextContentsTitle">
                            1F シェフズダイニング シンフォニー<br />
                            <div className="lp_swiperTextContentsTitle--bold">
                              新春ファミリーランチブッフェ
                            </div>
                          </div>
                          <ul>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2024年1月1日（元日）～1月3日（水）<br />
                                1部 11:30～13:00／2部 13:30～15:00
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　8,855円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※お子様、シニア料金あり
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/1c0mf1t5q9/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant11.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_tag">ランチ</div>
                          <div className="lp_swiperTextContentsTitle">
                            1F ロビーラウンジ フォンテーヌ<br />
                            <div className="lp_swiperTextContentsTitle--bold">
                              迎春アフタヌーンティー
                            </div>
                          </div>
                          <ul>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                               2023年12月26日（火）～<br />
                                2024年1月8日（月・祝）
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　6,325円〜
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※写真は2名様分です。
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/dcmponeumz/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="lp_tabContentsItem">
                <div className="">
                  <Swiper
                    onSwiper={setThumbsSwiper1}
                    loop={true}
                    spaceBetween={10}
                    slidesPerView={8}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper swiper2"
                  >
                    <SwiperSlide>
                    <img src="/assets/images/sp/newyear2024/restaurant2.png" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/assets/images/sp/newyear2024/restaurant3.png" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/assets/images/sp/newyear2024/restaurant6.png" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/assets/images/sp/newyear2024/restaurant8.png" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/assets/images/sp/newyear2024/restaurant9.png" />
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div className="border"></div>
                <Swiper
                  loop={true}
                  spaceBetween={0}
                  navigation={false}
                  thumbs={{ swiper: thumbsSwiper1 }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  <SwiperSlide>
                    <div className="lp_swiperContents">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant2.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_tag">ディナー</div>
                          <div className="lp_swiperTextContentsTitle">
                            20F 鉄板焼すみだ<br />
                            <div className="lp_swiperTextContentsTitle--bold">
                              年末・新春ディナー
                            </div>
                          </div>
                          <ul>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2023年12月31日（日）～2024年1月3日（水）<br />
                                1部 17:00～19:00／2部 19:30～21:30
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　22,770円 ～
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/6sl8nguujkot/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant3.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_tag">ディナー</div>
                          <div className="lp_swiperTextContentsTitle">
                            5F 日本料理 源氏香<br />
                            <div className="lp_swiperTextContentsTitle--bold">
                              年越しそば
                            </div>
                          </div>
                          <ul>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2023年12月31日（日）<br />
                                21:30～23:30（L.O. 23:00）
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　2,530円
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/frygofwfr/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant6.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_tag">ディナー</div>
                          <div className="lp_swiperTextContentsTitle">
                            5F 日本料理 源氏香<br />
                            <div className="lp_swiperTextContentsTitle--bold">
                              新春ディナー会席
                            </div>
                          </div>
                          <ul>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2024年1月1日（元日）～1月3日（水）<br />
                                1部 17:00～19:00／2部 19:30～21:30
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　17,710円～
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/ow6h6vx82mv/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant8.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_tag">ディナー</div>
                          <div className="lp_swiperTextContentsTitle">
                            B1F 中国料理 桂花苑<br />
                            <div className="lp_swiperTextContentsTitle--bold">
                              年末・新春ディナーコース
                            </div>
                          </div>
                          <ul>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2023年12月31日（日）～2024年1月3日（水）<br />
                                1部 17:00～19:00／2部 19:30～21:30
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　18,975円〜<br />
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/70af43m4s/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents">
                      <div className="lp_swiperImg lp_swiperImgLeft">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant9.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                        <div className="">
                          <div className="lp_tag">ディナー</div>
                          <div className="lp_swiperTextContentsTitle">
                            1F シェフズダイニング シンフォニー<br />
                            <div className="lp_swiperTextContentsTitle--bold">
                              新春ファミリーディナーブッフェ
                            </div>
                          </div>
                          <ul>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2024年1月1日（元日）～1月3日（水）<br />
                                1部 17:00～19:00／2部 19:30～21:30
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金</div>
                              <div className="lp_swiperTextContentsListText">
                                お一人様　8,855円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※お子様、シニア料金あり
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/restaurants/plan/uqzesnci2og/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div className="lp_tabContents u_sp">
              <div className="lp_tabContentsItemSp showSp">
                <div className="lp_contentsWrap">
                  <a href="https://www.rph.co.jp/restaurants/plan/lj4onaulnb/" target="_blank">
                    <div className="lp_contents">
                      <div className="lp_contentsImg">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant_small.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_contentsTextBox">
                        <div className="lp_contentsTextRed">
                          20F<br />
                          鉄板焼すみだ
                        </div>
                        <div className="lp_contentsTextBold">
                          年末・新春鉄板焼ステーキ食べ放題
                        </div>
                        <div className="lp_contentsText">
                          2023年12月30日（土）～2024年1月3日（水）
                        </div>
                      </div>
                    </div>
                  </a>
                  <a href="https://www.rph.co.jp/restaurants/plan/66oc8ia5s8f/" target="_blank">
                    <div className="lp_contents">
                      <div className="lp_contentsImg">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant4.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_contentsTextBox">
                        <div className="lp_contentsTextRed">
                          5F<br />
                          日本料理 源氏香
                        </div>
                        <div className="lp_contentsTextBold">
                          初釜
                        </div>
                        <div className="lp_contentsText">
                          2024年1月1日（元日）、<br />
                          1月2日（火）
                        </div>
                      </div>
                    </div>
                  </a>
                  <a href="https://www.rph.co.jp/restaurants/plan/y4ngtyltj/" target="_blank">
                    <div className="lp_contents">
                      <div className="lp_contentsImg">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant5.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_contentsTextBox">
                        <div className="lp_contentsTextRed">
                          5F<br />
                          日本料理 源氏香
                        </div>
                        <div className="lp_contentsTextBold">
                          新春ランチ会席
                        </div>
                        <div className="lp_contentsText">
                          2024年1月1日（元日）～<br />
                          1月3日（水）
                        </div>
                      </div>
                    </div>
                  </a>
                  <a href="https://www.rph.co.jp/restaurants/plan/z8tehjdqh/" target="_blank">
                    <div className="lp_contents">
                      <div className="lp_contentsImg">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant7.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_contentsTextBox">
                        <div className="lp_contentsTextRed">
                          B1F<br />
                          中国料理 桂花苑<br />
                        </div>
                        <div className="lp_contentsTextBold">
                          年末・新春ランチコース
                        </div>
                        <div className="lp_contentsText">
                          2023年12月31日（日）～<br />
                          2024年1月3日（水）
                        </div>
                      </div>
                    </div>
                  </a>
                  <a href="https://www.rph.co.jp/restaurants/plan/1c0mf1t5q9/" target="_blank">
                    <div className="lp_contents">
                      <div className="lp_contentsImg">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant10.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_contentsTextBox">
                        <div className="lp_contentsTextRed">
                          1F<br />
                          シェフズダイニング<br />
                          シンフォニー
                        </div>
                        <div className="lp_contentsTextBold">
                          新春ファミリーランチブッフェ
                        </div>
                        <div className="lp_contentsText">
                          2024年1月1日（元日）～<br />
                          1月3日（水）
                        </div>
                      </div>
                    </div>
                  </a>
                  <a href="https://www.rph.co.jp/restaurants/plan/dcmponeumz/" target="_blank">
                    <div className="lp_contents">
                      <div className="lp_contentsImg">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant11.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_contentsTextBox">
                        <div className="lp_contentsTextRed">
                          1F<br />
                          ロビーラウンジ フォンテーヌ
                        </div>
                        <div className="lp_contentsTextBold">
                          迎春アフタヌーンティー
                        </div>
                        <div className="lp_contentsText">
                          2023年12月26日（火）～<br />
                          2024年1月8日（月・祝）
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="lp_tabContentsItemSp">
                <div className="lp_contentsWrap">
                  <a href="https://www.rph.co.jp/restaurants/plan/6sl8nguujkot/" target="_blank">
                    <div className="lp_contents">
                      <div className="lp_contentsImg">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant2.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_contentsTextBox">
                        <div className="lp_contentsTextRed">
                          20F<br />
                          鉄板焼すみだ
                        </div>
                        <div className="lp_contentsTextBold">
                          年末・新春ディナー
                        </div>
                        <div className="lp_contentsText">
                          2023年12月31日（日）～<br />
                          2024年1月3日（水）
                        </div>
                      </div>
                    </div>
                  </a>
                  <a href="https://www.rph.co.jp/restaurants/plan/frygofwfr/" target="_blank">
                    <div className="lp_contents">
                      <div className="lp_contentsImg">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant3.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_contentsTextBox">
                        <div className="lp_contentsTextRed">
                          5F<br />
                          日本料理 源氏香<br />
                        </div>
                        <div className="lp_contentsTextBold">
                          年越しそば
                        </div>
                        <div className="lp_contentsText">
                          2023年12月31日（日）
                        </div>
                      </div>
                    </div>
                  </a>
                  <a href="https://www.rph.co.jp/restaurants/plan/ow6h6vx82mv/" target="_blank">
                    <div className="lp_contents">
                      <div className="lp_contentsImg">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant6.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_contentsTextBox">
                        <div className="lp_contentsTextRed">
                          5F<br />
                          日本料理 源氏香<br />
                        </div>
                        <div className="lp_contentsTextBold">
                          新春ディナー会席
                        </div>
                        <div className="lp_contentsText">
                          2024年1月1日（元日）～<br />
                          1月3日（水）
                        </div>
                      </div>
                    </div>
                  </a>
                  <a href="https://www.rph.co.jp/restaurants/plan/70af43m4s/" target="_blank">
                    <div className="lp_contents">
                      <div className="lp_contentsImg">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant8.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_contentsTextBox">
                        <div className="lp_contentsTextRed">
                          B1F<br />
                          中国料理 桂花苑<br />
                        </div>
                        <div className="lp_contentsTextBold">
                          年末・新春ディナーコース
                        </div>
                        <div className="lp_contentsText">
                          2023年12月31日（日）～<br />
                          2024年1月3日（水）
                        </div>
                      </div>
                    </div>
                  </a>
                  <a href="https://www.rph.co.jp/restaurants/plan/uqzesnci2og/" target="_blank">
                    <div className="lp_contents">
                      <div className="lp_contentsImg">
                        <AssetImage wrapClassName="gatsby-image-wrapper-custom" src="/assets/images/sp/newyear2024/restaurant9.png" alt=""></AssetImage>
                      </div>
                      <div className="lp_contentsTextBox">
                        <div className="lp_contentsTextRed">
                          1F<br />
                          シェフズダイニング<br />
                          シンフォニー
                        </div>
                        <div className="lp_contentsTextBold">
                          新春ファミリーディナーブッフェ
                        </div>
                        <div className="lp_contentsText">
                          2024年1月1日（元日）～<br />
                          1月3日（水）
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="lp_sect02" id="stay">
          <div className="item2-1"></div>
          <div className="item2-2"></div>
          <div className="lp_sect02Bg">
            <div className="lp_sectTitleWrap">
              <img src="/assets/images/sp/newyear2024/icon/icon_stay.png" alt=""/>
              <div className="lp_sectTitle lp_sectTitle02">
                ご宿泊プラン
              </div>
            </div>
            <div className="lp_sectTitleSub">Stay Plan</div>
            <div className="lp_tabList u_pc"></div>
            <div className="lp_tabContents u_pc">
              <div className="lp_tabContentsItem show">
                <Swiper
                  onSwiper={setThumbsSwiper2}
                  loop={true}
                  spaceBetween={10}
                  slidesPerView={8}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper swiper3"
                >
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2024/stay2.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2024/stay_small.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2024/stay3.png" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src="/assets/images/sp/newyear2024/stay4.png" />
                  </SwiperSlide>
                </Swiper>
                <div className="border"></div>
                <Swiper
                  loop={true}
                  spaceBetween={0}
                  navigation={false}
                  thumbs={{ swiper: thumbsSwiper2 }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  <SwiperSlide>
                    <div className="lp_swiperContents">
                      <div className="lp_swiperTextContents lp_swiperTextContentsLeft">
                        <div className="">
                          <div className="lp_swiperTextContentsTitle">
                            <div className="lp_swiperTextContentsTitle--bold">
                              CHIC TOKYO STAY 寿<br />
                              “KOTOBUKI”
                            </div>
                          </div>
                          <ul>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2023年12月31日（日）～2024年1月3日（水）<br />
                                3泊4日プラン
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金例</div>
                              <div className="lp_swiperTextContentsListText">
                                スタンダードフロア スタンダードツイン<br />
                                1室2名様ご利用時（夕・朝食付）<br />
                                ・ザ クラブ会員 : お一人様　197,000円<br />
                                ・一般 : お一人様　202,000円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※ 朝食付のプランもご用意しております。
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/stay/plan/bs8tdway-ci6/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                      <div className="lp_swiperImg lp_swiperImgRight">
                        <AssetImage src="/assets/images/sp/newyear2024/stay2.png" alt=""></AssetImage>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents">
                      <div className="lp_swiperTextContents lp_swiperTextContentsLeft">
                        <div className="">
                          <div className="lp_swiperTextContentsTitle">
                            <div className="lp_swiperTextContentsTitle--bold">
                              CHIC TOKYO STAY 初春 <br />
                              “HATSUHARU”
                            </div>
                          </div>
                          <ul>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2023年12月31日（日）～2024年1月2日（火）<br />
                                2泊3日プラン
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金例</div>
                              <div className="lp_swiperTextContentsListText">
                                スタンダードフロア スタンダードツイン<br />
                                1室2名様ご利用時（夕・朝食付）<br />
                                ・ザ クラブ会員 : お一人様　152,000円<br />
                                ・一般 : お一人様　157,000円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※ 朝食付のプランもご用意しております。
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/stay/plan/bs8tdway-ci6/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                      <div className="lp_swiperImg lp_swiperImgRight">
                        <AssetImage src="/assets/images/sp/newyear2024/stay.png" alt=""></AssetImage>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents">
                      <div className="lp_swiperTextContents lp_swiperTextContentsLeft">
                        <div className="">
                          <div className="lp_swiperTextContentsTitle">
                            <div className="lp_swiperTextContentsTitle--bold">
                              CHIC TOKYO STAY 新春<br />
                              “SHINSHUN”
                            </div>
                          </div>
                          <ul>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2024年1月1日（元日）～1月3日（水）<br />
                                2泊3日プラン
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金例</div>
                              <div className="lp_swiperTextContentsListText">
                                スタンダードフロア スタンダードツイン<br />
                                1室2名様ご利用時（夕・朝食付）<br />
                                ・ザ クラブ会員 : お一人様　125,000円<br />
                                ・一般 : お一人様　130,000円
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※ 朝食付のプランもご用意しております。
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/stay/plan/bs8tdway-ci6/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                      <div className="lp_swiperImg lp_swiperImgRight">
                        <AssetImage src="/assets/images/sp/newyear2024/stay3.png" alt=""></AssetImage>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lp_swiperContents">
                      <div className="lp_swiperTextContents lp_swiperTextContentsLeft">
                        <div className="">
                          <div className="lp_swiperTextContentsTitle">
                            <div className="lp_swiperTextContentsTitle--bold">
                              ロングステイプラン
                            </div>
                          </div>
                          <ul>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">期間</div>
                              <div className="lp_swiperTextContentsListText">
                                2023年12月26日（火）～12月30日（土）、<br />
                                2024年1月3日（水）～1月7日（日）
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--bold">料金例</div>
                              <div className="lp_swiperTextContentsListText">
                                スタンダードフロア<br />
                                1室2名様ご利用時（朝食付）<br />
                                1泊あたり：お一人様　13,500円～
                              </div>
                            </li>
                            <li className="lp_swiperTextContentsList">
                              <div className="lp_swiperTextContentsListText--small">
                                ※ CHIC TOKYO STAYプランの前後泊のみでご利用いただけます。
                              </div>
                            </li>
                          </ul>
                        </div>
                        <a href="https://www.rph.co.jp/stay/plan/bs8tdway-ci6/" target="_blank">
                          <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                          </div>
                        </a>
                      </div>
                      <div className="lp_swiperImg lp_swiperImgRight">
                        <AssetImage src="/assets/images/sp/newyear2024/stay4.png" alt=""></AssetImage>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div className="lp_tabContents u_sp">
              <div className="lp_contentsWrap">
                <a href="https://www.rph.co.jp/stay/plan/bs8tdway-ci6" target="_blank">
                  <div className="lp_contents">
                    <div className="lp_contentsImg">
                      <AssetImage src="/assets/images/sp/newyear2024/stay2.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_contentsTextBox">
                      <div className="lp_contentsTextBold">
                        CHIC TOKYO STAY 寿<br />
                        “KOTOBUKI”
                      </div>
                      <div className="lp_contentsText">
                        2023年12月31日（日）<br/>
                        ～2024年1月3日（水）
                      </div>
                    </div>
                  </div>
                </a>
                <a href="https://www.rph.co.jp/stay/plan/bs8tdway-ci6" target="_blank">
                  <div className="lp_contents">
                    <div className="lp_contentsImg">
                      <AssetImage src="/assets/images/sp/newyear2024/stay_small.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_contentsTextBox">
                      <div className="lp_contentsTextBold">
                        CHIC TOKYO STAY 初春<br />
                        “HATSUHARU”
                      </div>
                      <div className="lp_contentsText">
                        2023年12月31日（日）<br />
                        ～2024年1月2日（火）
                      </div>
                    </div>
                  </div>
                </a>
                <a href="https://www.rph.co.jp/stay/plan/bs8tdway-ci6" target="_blank">
                  <div className="lp_contents">
                    <div className="lp_contentsImg">
                      <AssetImage src="/assets/images/sp/newyear2024/stay3.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_contentsTextBox">
                      <div className="lp_contentsTextBold">
                        CHIC TOKYO STAY 新春<br />
                        “SHINSHUN”
                      </div>
                      <div className="lp_contentsText">
                        2024年1月1日（元日）<br />
                        ～1月3日（水）
                      </div>
                    </div>
                  </div>
                </a>
                <a href="https://www.rph.co.jp/stay/plan/bs8tdway-ci6" target="_blank">
                  <div className="lp_contents">
                    <div className="lp_contentsImg">
                      <AssetImage src="/assets/images/sp/newyear2024/stay4.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_contentsTextBox">
                      <div className="lp_contentsTextBold">
                        ロングステイプラン
                      </div>
                      <div className="lp_contentsText">
                        2023年12月26日（火）<br />
                        ～12月30日（土）、<br />
                        2024年1月3日（水）<br />
                        ～1月7日（日）
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="lp_sect03" id="event">
          <div className="item3-1"></div>
          <div className="item3-2"></div>
          <div className="item3-3"></div>
          <div className="lp_sect03Bg">
            <div className="lp_sectTitleWrap">
              <img src="/assets/images/sp/newyear2024/icon/icon_event.png" alt=""/>
              <div className="lp_sectTitle lp_sectTitle03">
                イベント
              </div>
            </div>
            <div className="lp_sectTitleSub">Event</div>
            <div className="lp_tabList u_pc"></div>
            <div className="lp_tabContentsItem u_pc show">
              <Swiper
                onSwiper={setThumbsSwiper3}
                loop={true}
                spaceBetween={10}
                slidesPerView={8}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper swiper1"
              >
                <SwiperSlide>
                  <img src="/assets/images/sp/newyear2024/event.png" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/assets/images/sp/newyear2024/event2.png" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/assets/images/sp/newyear2024/event3.png" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/assets/images/sp/newyear2024/event4.png" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/assets/images/sp/newyear2024/event5.png" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/assets/images/sp/newyear2024/event6.png" />
                </SwiperSlide>
              </Swiper>
              <div className="border"></div>
              <Swiper
                loop={true}
                spaceBetween={0}
                navigation={false}
                thumbs={{ swiper: thumbsSwiper3 }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
              >
                <SwiperSlide>
                  <div className="lp_swiperContents">
                    <div className="lp_swiperImg lp_swiperImgLeft">
                      <AssetImage src="/assets/images/sp/newyear2024/event.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                      <div className="">
                        <div className="lp_swiperTextContentsTitle">
                          1F ロビー／2F 宴会場<br />
                          <div className="lp_swiperTextContentsTitle--bold">
                            縁日フェア
                          </div>
                        </div>
                        <ul>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">期間</div>
                            <div className="lp_swiperTextContentsListText">
                              2024年1月1日（元日）、1月2日（火）<br />
                              10:00～18:00
                            </div>
                          </li>
                        </ul>
                      </div>
                      <a href="https://www.rph.co.jp/information/tio5r18mt/" target="_blank">
                        <div className="lp_swiperTextContentsButton">
                          詳細はこちら →
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="lp_swiperContents">
                    <div className="lp_swiperImg lp_swiperImgLeft">
                      <AssetImage src="/assets/images/sp/newyear2024/event2.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                      <div className="">
                        <div className="lp_swiperTextContentsTitle">
                          3F 宴会場 ロイヤルホール<br />
                          <div className="lp_swiperTextContentsTitle--bold">
                            ホテルでおせち
                          </div>
                        </div>
                        <ul>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">期間</div>
                            <div className="lp_swiperTextContentsListText">
                              2024年1月1日（元日）<br />
                              7:30～10:30（L.O. 10:00）
                            </div>
                          </li>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">料金</div>
                            <div className="lp_swiperTextContentsListText">
                              お一人様　7,590円
                            </div>
                          </li>
                        </ul>
                      </div>
                      <a href="https://www.rph.co.jp/information/8dxvy4b5gly/" target="_blank">
                        <div className="lp_swiperTextContentsButton">
                          詳細はこちら →
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="lp_swiperContents">
                    <div className="lp_swiperImg lp_swiperImgLeft">
                      <AssetImage src="/assets/images/sp/newyear2024/event3.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                      <div className="">
                        <div className="lp_swiperTextContentsTitle">
                          3F 宴会場 ロイヤルホール<br />
                          <div className="lp_swiperTextContentsTitle--bold">
                            新春 満福団らんブッフェ
                          </div>
                        </div>
                        <ul>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">期間</div>
                            <div className="lp_swiperTextContentsListText">
                              2024年1月1日（元日）、1月2日（火）<br />
                              11:30～15:00（L.O. 14:30）<br />
                              17:00～21:00（L.O. 20:30）
                            </div>
                          </li>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">料金</div>
                            <div className="lp_swiperTextContentsListText">
                              お一人様　10,120円
                            </div>
                          </li>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--small">
                              ※ お子様、シニア料金あり
                            </div>
                          </li>
                        </ul>
                      </div>
                      <a href="https://www.rph.co.jp/information/izykgyh44t/" target="_blank">
                        <div className="lp_swiperTextContentsButton">
                          詳細はこちら →
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="lp_swiperContents">
                    <div className="lp_swiperImg lp_swiperImgLeft">
                      <AssetImage src="/assets/images/sp/newyear2024/event4.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                      <div className="">
                        <div className="lp_swiperTextContentsTitle">
                          20F 宴会場 パラッツオ<br />
                          <div className="lp_swiperTextContentsTitle--bold">
                            新春 パラッツオランチブッフェ
                          </div>
                        </div>
                        <ul>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">期間</div>
                            <div className="lp_swiperTextContentsListText">
                              2024年1月1日（元日）、1月2日（火）<br />
                              1部 11:00～13:00（L.O. 12:00）／<br />
                              2部 13:30～15:30（L.O. 14:30）
                            </div>
                          </li>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">料金</div>
                            <div className="lp_swiperTextContentsListText">
                              お一人様　10,752円
                            </div>
                          </li>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--small">
                              ※ お子様、シニア料金あり
                            </div>
                          </li>
                        </ul>
                      </div>
                      <a href="https://www.rph.co.jp/information/k14zroqz1e/" target="_blank">
                        <div className="lp_swiperTextContentsButton">
                          詳細はこちら →
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="lp_swiperContents">
                    <div className="lp_swiperImg lp_swiperImgLeft">
                      <AssetImage src="/assets/images/sp/newyear2024/event5.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                      <div className="">
                        <div className="lp_swiperTextContentsTitle">
                          1F ロビー<br />
                          <div className="lp_swiperTextContentsTitle--bold">
                            もちつき
                          </div>
                        </div>
                        <ul>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText">
                              お正月飾りに彩られたロビーで、一年の幸福を祈願しながら、お囃子や太鼓とともに威勢よくお餅をつきあげます。
                            </div>
                          </li>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">期間</div>
                            <div className="lp_swiperTextContentsListText">
                              2024年1月1日（元日）<br />
                              11:00～／14:00～ 各回約30分
                            </div>
                          </li>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--small">
                              ※ ご予約不要、鑑賞無料
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="lp_swiperContents">
                    <div className="lp_swiperImg lp_swiperImgLeft">
                      <AssetImage src="/assets/images/sp/newyear2024/event6.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_swiperTextContents lp_swiperTextContentsRight">
                      <div className="">
                        <div className="lp_swiperTextContentsTitle">
                          1F ロビー<br />
                          <div className="lp_swiperTextContentsTitle--bold">
                            福袋
                          </div>
                        </div>
                        <ul>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">期間</div>
                            <div className="lp_swiperTextContentsListText">
                              2024年1月1日（元日）、1月2日（火）<br />
                              10:00～18:00
                            </div>
                          </li>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--bold">料金</div>
                            <div className="lp_swiperTextContentsListText">
                              30,000円～
                            </div>
                          </li>
                          <li className="lp_swiperTextContentsList">
                            <div className="lp_swiperTextContentsListText--small">
                              ※ 料金には消費税が含まれます。
                            </div>
                          </li>
                        </ul>
                      </div>
                      <a href="https://www.rph.co.jp/information/3uvf8ekzf4i/" target="_blank">
                        <div className="lp_swiperTextContentsButton">
                            詳細はこちら →
                        </div>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="lp_tabContents u_sp">
              <div className="lp_contentsWrap">
                <a href="https://www.rph.co.jp/information/tio5r18mt/" target="_blank">
                  <div className="lp_contents">
                    <div className="lp_contentsImg">
                      <AssetImage src="/assets/images/sp/newyear2024/event.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_contentsTextBox">
                      <div className="lp_contentsTextBold">
                        縁日フェア
                      </div>
                      <div className="lp_contentsText">
                        2024年1月1日（元日）、<br />
                        1月2日（火）
                      </div>
                    </div>
                  </div>
                </a>
                <a href="https://www.rph.co.jp/information/8dxvy4b5gly/" target="_blank">
                  <div className="lp_contents">
                    <div className="lp_contentsImg">
                      <AssetImage src="/assets/images/sp/newyear2024/event2.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_contentsTextBox">
                      <div className="lp_contentsTextBold">
                        ホテルでおせち
                      </div>
                      <div className="lp_contentsText">
                        2024年1月1日（元日）
                      </div>
                    </div>
                  </div>
                </a>
                <a href="https://www.rph.co.jp/information/izykgyh44t/" target="_blank">
                  <div className="lp_contents">
                    <div className="lp_contentsImg">
                      <AssetImage src="/assets/images/sp/newyear2024/event3.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_contentsTextBox">
                      <div className="lp_contentsTextBold">
                        新春 満福団らんブッフェ
                      </div>
                      <div className="lp_contentsText">
                        2024年1月1日（元日）、<br />
                        1月2日（火）
                      </div>
                    </div>
                  </div>
                </a>
                <a href="https://www.rph.co.jp/information/k14zroqz1e/" target="_blank">
                  <div className="lp_contents">
                    <div className="lp_contentsImg">
                      <AssetImage src="/assets/images/sp/newyear2024/event4.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_contentsTextBox">
                      <div className="lp_contentsTextBold">
                        新春 パラッツオランチブッフェ
                      </div>
                      <div className="lp_contentsText">
                        2024年1月1日（元日）、<br />
                        1月2日（火）
                      </div>
                    </div>
                  </div>
                </a>
                <button
                  onClick={openModalEvent}
                >
                  <div className="lp_contents">
                    <div className="lp_contentsImg">
                      <AssetImage src="/assets/images/sp/newyear2024/event5.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_contentsTextBox">
                      <div className="lp_contentsTextBold">
                        もちつき
                      </div>
                      <div className="lp_contentsText">
                        2024年1月1日（元日）
                      </div>
                    </div>
                  </div>
                </button>
                <a href="https://www.rph.co.jp/information/3uvf8ekzf4i/" target="_blank">
                  <div className="lp_contents">
                    <div className="lp_contentsImg">
                      <AssetImage src="/assets/images/sp/newyear2024/event6.png" alt=""></AssetImage>
                    </div>
                    <div className="lp_contentsTextBox">
                      <div className="lp_contentsTextBold">
                        福袋
                      </div>
                      <div className="lp_contentsText">
                        2024年1月1日（元日）、<br />
                        1月2日（火）
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="lp_sect04" id="osechi">
          <div className="item4-1"></div>
          <div className="item4-2 u_pc"></div>
          <div className="item4-3"></div>
          <div className="item4-4"></div>
          <div className="lp_sect04Bg">
            <div className="lp_sectTitleWrap">
              <img src="/assets/images/sp/newyear2024/icon/icon_osechi.png" alt=""/>
              <div className="lp_sectTitle lp_sectTitle04">
                おせち料理
              </div>
            </div>
            <div className="lp_sectTitleSub">Osechi</div>
            <div className="lp_sectContents">
              <button
                onClick={openModal}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/newyear2024/osechi1.png" alt=""/>
                  <div className="lp_sectContentsItemText">
                    想咲おせち<br />
                    <div className="lp_sectContentsItemText--small">日本料理</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal02}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/newyear2024/osechi2.png" alt=""/>
                  <div className="lp_sectContentsItemText">
                    おせち料理 三段<br />
                    <div className="lp_sectContentsItemText--small">日本料理・フランス料理・中国料理</div>
                  </div>
                </div>
              </button>
              <button
                onClick={openModal03}
              >
                <div className="lp_sectContentsItem">
                  <img src="/assets/images/sp/newyear2024/osechi3.png" alt=""/>
                  <div className="lp_sectContentsItemText">
                    おせち料理 二段<br />
                    <div className="lp_sectContentsItemText--small">日本料理</div>
                  </div>
                </div>
              </button>
            </div>
            <div className="lp_sectButtonWrap">
              <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=64cb10793a872300b52655e8&num_people=1&start_date=2023-12-31" target="_blank">
                <div className="lp_sectButton">
                  おせち料理のご予約はこちら →
                </div>
              </a>
            </div>
          </div>
        </section>
        <section className="lp_sect05" id="">
          <div className="item5-1 u_sp"></div>
          <div className="item5-2 u_sp"></div>
          <div className="lp_attentionWrap">
            <ul className="lp_attentionText">
              <li>※レストランプラン、イベントの料金には消費税・サービス料が含まれます。</li>
              <li>※ご宿泊プランの料金には消費税・サービス料・東京都宿泊税が含まれます。</li>
              <li>※おせち料理の料金には消費税（8％）が含まれます。</li>
              <li>※⾷材の⼊荷状況により、内容を変更させていただく場合がございます。</li>
              <li>※限定商品のため、販売終了の際はご容赦ください。</li>
              <li>※掲載の写真は実際と異なる場合がございます。</li>
              <li>
                ※今後の状況によっては営業内容の変更、営業を休止する場合がございます。またイベントについてもやむを得ず中止とさせていただく可能性もございます。<br />
                詳しくはホームページをご覧ください。何卒、ご了承の程よろしくお願い申しあげます。
              </li>
            </ul>
          </div>
        </section>
        <div className="lp_newYearFooter disnon u_sp">
          <ul>
            <li>
              <a href="#restaurant">
                <div className="lp_newYearFooterImg">
                  <AssetImage
                    src="/assets/images/sp/newyear2024/icon/restaurant_icon_w.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="lp_newYearFooterText">レストラン</div>
              </a>
            </li>
            <li>
              <a href="#stay">
                <div className="lp_newYearFooterImg">
                  <AssetImage
                    src="/assets/images/sp/newyear2024/icon/stay_icon_w.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
              <div className="lp_newYearFooterText">ステイ</div>
              </a>
            </li>
            <li>
              <a href="#event">
                <div className="lp_newYearFooterImg">
                  <AssetImage
                    src="/assets/images/sp/newyear2024/icon/event_icon_w.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="lp_newYearFooterText">イベント</div>
              </a>
            </li>
            <li>
              <a href="#osechi">
                <div className="lp_newYearFooterImg">
                  <AssetImage
                    src="/assets/images/sp/newyear2024/icon/osechi_icon_w.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="lp_newYearFooterText">おせち料理</div>
              </a>
            </li>
          </ul>
        </div>
        {/* modal */}
        {modalFlagEvent &&  (
          <CLpModalNewyear onClose={openModalEvent} exClass="p_access_modal">
            <div className="c_lpModalNewyear">
              <div className="c_lpModalNewyearTitleText u_sp">
                1F ロビー<br />
                <div className="c_lpModalNewyearTitle">
                  もちつき
                </div>
              </div>
              <div className="c_lpModalNewyearImg">
                <img src="/assets/images/sp/newyear2024/event5.png" alt=""/>
              </div>
              <div className="c_lpModalNewyearTextWrap">
                <div>
                  <div className="c_lpModalNewyearTitle u_pc">
                    1F ロビー<br />
                    <div className="c_lpModalNewyearTitleBold">
                      もちつき
                    </div>
                  </div>
                  <div className="c_lpModalNewyearText">
                    お正月飾りに彩られたロビーで、一年の幸福を祈願しながら、お囃子や太鼓とともに威勢よくお餅をつきあげます。
                  </div>
                  <ul>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">期間</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        2024年1月1日（元日）<br />
                        11:00～／14:00～ 各回約30分
                      </div>
                    </li>
                  </ul>
                  <div className="c_lpModalNewyearAttention">
                    ※ ご予約不要、鑑賞無料
                  </div>
                </div>
              </div>
            </div>
          </CLpModalNewyear>
        )}
        {modalFlag && (
          <CLpModalNewyear onClose={openModal} exClass="p_access_modal">
            <div className="c_lpModalNewyear">
              <div className="c_lpModalNewyearTitle u_sp">
                想咲おせち<br />
                <div className="c_lpModalNewyearTitleBold">
                  日本料理
                </div>
              </div>
              <div className="c_lpModalNewyearImg">
                <img src="/assets/images/sp/newyear2024/osechi1.png" alt=""/>
              </div>
              <div className="c_lpModalNewyearTextWrap">
                <div>
                  <div className="c_lpModalNewyearTitle u_pc">
                    想咲おせち<br />
                    <div className="c_lpModalNewyearTitleBold">
                      日本料理
                    </div>
                  </div>
                  <div className="c_lpModalNewyearText">
                    日本料理長　岩田 好輝が一品一品素材を選りすぐり、新年の平安を願って作り上げる「想咲おせち」。縁起を担ぐ品々を丁寧に仕込み、美しく盛合せた三段重です。
                  </div>
                  <ul>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">ご予約期間</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        2023年12月20日（水）19:00まで
                      </div>
                    </li>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">料金</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        108,000円
                      </div>
                    </li>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">限定数</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        15個／要予約
                      </div>
                    </li>
                  </ul>
                  <div className="c_lpModalNewyearAttention">
                    ※ 限定商品のため、販売終了の際はご容赦ください。
                  </div>
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=64cb10793a872300b52655e8&num_people=1&start_date=2023-12-31" target="_blank">
                  <div className="c_lpModalNewyearButton">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </CLpModalNewyear>
        )}
        {modalFlag02 && (
          <CLpModalNewyear onClose={openModal02} exClass="p_access_modal">
            <div className="c_lpModalNewyear">
              <div className="c_lpModalNewyearTitle u_sp">
                おせち料理 三段<br />
                <div className="c_lpModalNewyearTitleBold">
                  日本料理・フランス料理・中国料理
                </div>
              </div>
              <div className="c_lpModalNewyearImg">
                <img src="/assets/images/sp/newyear2024/osechi2.png" alt=""/>
              </div>
              <div className="c_lpModalNewyearTextWrap">
                <div>
                  <div className="c_lpModalNewyearTitle u_pc">
                    おせち料理 三段<br />
                    <div className="c_lpModalNewyearTitleBold">
                      日本料理・フランス料理・中国料理
                    </div>
                  </div>
                  <div className="c_lpModalNewyearText">
                    日本料理・フランス料理・中国料理を一度に味わえる、幅広い世代の方に好まれる豪華な三段重。多彩な美味とともに、新年の幕開けをお楽しみください。
                  </div>
                  <ul>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">ご予約期間</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        2023年12月20日（水）19:00まで
                      </div>
                    </li>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">料金</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        59,400円
                      </div>
                    </li>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">限定数</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        100個／要予約
                      </div>
                    </li>
                  </ul>
                  <div className="c_lpModalNewyearAttention">
                    ※ 限定商品のため、販売終了の際はご容赦ください。
                  </div>
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=64cb10793a872300b52655e8&num_people=1&start_date=2023-12-31" target="_blank">
                  <div className="c_lpModalNewyearButton">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </CLpModalNewyear>
        )}
        {modalFlag03 && (
          <CLpModalNewyear onClose={openModal03} exClass="p_access_modal">
            <div className="c_lpModalNewyear">
              <div className="c_lpModalNewyearTitle u_sp">
                おせち料理 二段<br />
                <div className="c_lpModalNewyearTitleBold">
                  日本料理
                </div>
              </div>
              <div className="c_lpModalNewyearImg">
                <img src="/assets/images/sp/newyear2024/osechi3.png" alt=""/>
              </div>
              <div className="c_lpModalNewyearTextWrap">
                <div>
                  <div className="c_lpModalNewyearTitle u_pc">
                    おせち料理 二段<br />
                    <div className="c_lpModalNewyearTitleBold">
                      日本料理
                    </div>
                  </div>
                  <div className="c_lpModalNewyearText">
                    日本料理長こだわりの品々を種類豊富に詰合せ、手間を惜しまず匠の技で味わい深く仕上げました。少人数のご家庭にも人気のおせち料理です。
                  </div>
                  <ul>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">ご予約期間</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        2023年12月20日（水）19:00まで
                      </div>
                    </li>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">料金</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        41,040円
                      </div>
                    </li>
                    <li className="c_lpModalNewyearTextContentsList">
                      <div className="c_lpModalNewyearTextContentsListTextBold">限定数</div>
                      <div className="c_lpModalNewyearTextContentsListText">
                        100個／要予約
                      </div>
                    </li>
                  </ul>
                  <div className="c_lpModalNewyearAttention">
                    ※ 限定商品のため、販売終了の際はご容赦ください。
                  </div>
                </div>
                <a href="https://www.tablecheck.com/shops/royalparkhotel-tokyo-iki/reserve?menu_lists[]=64cb10793a872300b52655e8&num_people=1&start_date=2023-12-31" target="_blank">
                  <div className="c_lpModalNewyearButton">
                    ご予約はこちら →
                  </div>
                </a>
              </div>
            </div>
          </CLpModalNewyear>
        )}
      </div>
    </LayoutLp>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
